import React, { useEffect, useState, useRef } from 'react'
import API from "../../../../modules/api";
import Util from "../../../../modules/utils";
import * as appConsts from "../../../../store/consts";
import './HomeTopBannerSection.css'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang,addChangeTranslateArr } from '../../../../store/pathActions'
import Languages from "./Languages";
function HomeTopBannerSection() {
    const utl = new Util();
    const dispatch = useDispatch();
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const [homebannerState, setHomebannerState] = useState(
        {
            banner1_url: "/assets/banners/banner_01.png",
            banner2_url: "/assets/banners/banner_02.png",
            banner3_url: "/assets/banners/banner_03.png",
            banner4_url: "/assets/banners/banner_04.png",
            text1: "Powerful, easier and faster",
            text2: "Grow your software",
            text3: "faster & easier with Dashbord",
            text4: "The World's simplest and fast platform that brings everything together",
            text5: "Enter your email",
            text6: "TRY FOR FREE",
            text7: "Already using software?",
            text8: "Sign in",
        }
    );
    useEffect(() => {
        if(tranlates==undefined){
            dispatch(addChangeTranslateArr(lang))
        }
        loadData();

    }, [])
    useEffect(() => {
        if(tranlates==undefined){
            dispatch(addChangeTranslateArr(lang))
        }
        loadData();

    }, [lang,tranlates])







    const loadData=()=>{


        try {
            tranlates.then(result2 => {
             
                let arrNames = [];
                for (let index = 1; index <= 4; index++) {
        
                }
        
        
                for (let index = 1; index <= 8; index++) {
                    arrNames = [...arrNames, `home-banner-text${index}`];
        
                }
                console.log("txt", arrNames);
                (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                    let obj = {};
                    for (let index = 1; index <= 4; index++) {
                        arrNames = [...arrNames, `home-banner${index}-attach-id`];
                        if (result[`home-banner${index}-attach-id`] != null) {
                            const myVal = result[`home-banner${index}-attach-id`];
                           
                            obj[`banner${index}_url`] = `${appConsts.api_url}/api/attachments/file/${myVal}`;
        
        
                        }
                    }
        
        
        
        
                    for (let index = 1; index <= 8; index++) {
                        arrNames = [...arrNames, `home-banner-text${index}`];
                        if (result[`home-banner-text${index}`] != null) {
                            const myVal = result[`home-banner-text${index}`];
                            obj[`text${index}`] = utl.translateWord(result2,myVal);
        
        
                        }
                    }
                    setHomebannerState({ ...homebannerState, ...obj })
                   
                });


            })
        } catch (error) {

        }

       

    }
  return (
   
    <section className="banner_section">
        <div className="container">
            <div className="banner_text">
                <div className="ban_inner_text aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                    <span>{homebannerState.text1}</span>
                    <h1>{homebannerState.text2} <br /> {homebannerState.text3}</h1>
                    <p>{homebannerState.text4}</p>
                </div>
                <form action="submit" data-aos="fade-up" data-aos-duration="1500" className="aos-init aos-animate">
                    <div className="form-group">
                        <input type="text" className="form-control" placeholder={homebannerState.text5} />
                        <button className="btn btn_main">{homebannerState.text6} <i className="icofont-arrow-right"></i></button>
                    </div>
                    <span>{homebannerState.text7} <a href="#">{homebannerState.text8}</a></span>
                </form>
            </div>
            <div className="banner_images aos-init aos-animate" data-aos="fade-up" data-aos-duration="1500">
                <img src="/assets/images/banner_01.png" alt="image" />
                <div className="sub_images">
                    <img className="moving_animation" src="/assets/images/banner_02.png" alt="image" />
                    <img className="moving_animation" src="/assets/images/banner_03.png" alt="image" />
                    <img className="moving_animation" src="/assets/images/banner_04.png" alt="image" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default HomeTopBannerSection