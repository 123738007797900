import React, { useCallback, useEffect } from "react";
import Wrapper from "../../../hoc/Wrapper/Wrapper";
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangePageTitle } from '../../../store/pathActions'
const About = (props) => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(addChangePageTitle("درباره ما"))

    })
    return (
        <Wrapper>
            {console.log(props)}

            <h1>ABout</h1>
        </Wrapper>

    )


}

export default About