import React, { useCallback, useEffect, useState } from "react";
import * as appConst from '../../../store/consts'

import { addChangePageTitle } from '../../../store/pathActions'
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux'


import Utils from "../../../modules/utils";
import ProductSide from "../../../components/site/Product/ProductSide";
import './product.css'
function SiteProduct() {
  const utl = new Utils();


  const { id } = useParams();
  const lang = useSelector((state) => state.lang)
  const tranlates = useSelector((state) => state.tranlates);
  const dispatch = useDispatch();
  const [productState, setProductState] = useState(null)

  //   useEffect(() => {


  //     loadData();
  //     loadTitle();

  // })
  useEffect(() => {
    loadData();


  }, [id,lang])

  const loadData = async () => {
    const response = await fetch(`${appConst.api_url}/api/products/complete/${id}`)
    const result = await response.json();
    console.clear();
    console.log('result', result);
    try {
      tranlates.then(result2 => {


        if (result == null)
          return;
        result.name = utl.translateWord(result2, result.name)
        result.description = utl.translateWord(result2, result.description)
        result.product.name = utl.translateWord(result2, result.product.name)
        result.product.description = utl.translateWord(result2, result.product.description)
        result.details = result.details.map((item) => {

          item.name = utl.translateWord(result2, item.name)
          item.val = utl.translateWord(result2, item.val)

          return item;
        })
        console.log('result2', result);
        setProductState(result);

        loadTitle();


      })
    } catch {
      setProductState(result);

      loadTitle();
    }


  }
  const loadTitle = () => {
    let temp = "محصول";

    if (productState != null) {
      temp = `${productState.product.name} ${productState.name}`
    }

    dispatch(addChangePageTitle(temp))
  }

  return (
    <section className="service_detail_section">
      <div className="container">
        {
          productState != null ? <div className="row mb-5">
            <ProductSide product={productState}/>
            <div className="col-lg-8">

              <div className="service_left_side">
                <div className="section_title aos-init" data-aos="fade-up" data-aos-duration="2000">
                  <h2>{productState.product.name}</h2>
                  <p>{productState.product.description}</p>
                </div>
                {/* <div className="img aos-init" data-aos="fade-up" data-aos-duration="2000">
                <img src="images/new/service-img.png" alt="image"/>
              </div> */}
                <h3 data-aos="fade-up" data-aos-duration="1500" className="aos-init">{productState.name}</h3>
                <p data-aos="fade-up" data-aos-duration="1500" className="aos-init">{productState.description}
                </p>
                <ul className="list_block aos-init" data-aos="fade-up" data-aos-duration="1500">

                  {
                    productState.details.map((item) => {
                      return (item.val != '[OK]' && item.val != '[NO]' ? <li>
                        <h3><i className="icofont-duotone icofont-link"></i> {item.name}</h3>
                        <p>{item.val}</p>
                      </li> : null)

                    })
                  }

                </ul>

              </div>
          

            </div>
        

          </div> : null

        }

<div className="row mt-5">
           <div className="col-lg-12">


<section className="need_section innerpage_needsection aos-init aos-animate" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
  <div className="container">
    <div className="need_block">
      <div className="need_text">
        <div className="section_title">
          <h2>Need support ? contact our team</h2>
          <p><i className="icofont-clock-time"></i> Mon - Fri: 9 am to 5 am</p>
        </div>
      </div>
      <div className="need_action">
        <a href="tel:1234567899" className="btn"><i className="icofont-phone-circle"></i> +1 123 456 7890</a>
        <a href="faq.html" className="faq_btn">Read The FAQ </a>
      </div>
    </div>
  </div>
</section>

</div>
           </div>

      </div>
    </section>
  )
}

export default SiteProduct