import React , { useEffect, useState } from 'react';
import RouteLink from '../../../Routing/RouteLink';
import { useDispatch,useSelector } from 'react-redux'

import {addChangeView, addChangeLang,addChangeTranslateArr } from '../../../../store/pathActions'
import { useNavigate } from "react-router-dom";
import Utils from "../../../../modules/utils";
function AuthContainer(props) {
    const utl = new Utils();
    const navigate = useNavigate();
    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates);
    const dispatch = useDispatch();

    const [myState,setMyState]=useState({
        title:props.title,
        dsc:props.dsc,
        btn_back_title:"بازگشت به صفحه اصلی"
    })

        const onHomeclicked=(e)=>{
            e.preventDefault();
            dispatch(addChangeView("/"))
            if (window.history.replaceState) {
                navigate("/")

               
            }
        }
        useEffect(()=>{
            loadTransaltes();
           

        },[])
        useEffect(()=>{
            loadTransaltes();
           

        },[tranlates])


        const loadTransaltes=()=>{
            try {
                tranlates.then(result => {

                    const obj = {
                        title:utl.translateWord(result,props.title),
                        dsc:props.dsc,
                        btn_back_title:utl.translateWord(result,"بازگشت به صفحه اصلی")
                    }
                  
                    setMyState({...obj})
                  

                })
            }catch{

            }
        }
  return (
    <section className="signup_section">

    <div className="top_part">
      <RouteLink href="/" className="back_btn" click={(event)=>onHomeclicked(event)}><i className="icofont-arrow-left"></i> {myState.btn_back_title}</RouteLink>
    </div>

    <div className="profile_block sign-in">
     
      <div className="profile_side">
        <div className="top_side">
        <RouteLink href="/" click={(event)=>onHomeclicked(event)}><img src="/assets/images/Logo.png" alt="image"/></RouteLink>

          <div className="rating">
            <span><i className="icofont-star"></i></span>
            <span><i className="icofont-star"></i></span>
            <span><i className="icofont-star"></i></span>
            <span><i className="icofont-star"></i></span>
            <span><i className="icofont-star"></i></span>
          </div>
          <p>Lorem Ipsum is simply dummy text of the print ing and typese tting us orem Ipsum has been lorem
            beenthe standar ddummy Lorem Ipsum is simply mmy text of the print ing and typese tting us.</p>
         
        </div>
      
      </div>
     
      <div className="signup_form">
      <div className="section_title">
              <h2>{myState.title}</h2>
              <p>{myState.dsc}</p>
            </div>
     {props.children}
      </div>
    </div>
  </section>
  )
}

export default AuthContainer