import React from "react";
import HomeTopBannerSection from "./HomeTopBannerSection";
const TopHomeWrapper = (props) => {

    return (

        <div className="top_home_wraper">



            <header className="fixed">

                <div className="container">

                    <nav className="navbar navbar-expand-lg">
                        <a className="navbar-brand" href="index.html">
                            <img src={props.logo} alt="image" />
                        </a>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon">

                                <span className="toggle-wrap">
                                    <span className="toggle-bar"></span>
                                </span>
                            </span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            {props.children}
                        </div>
                    </nav>

                </div>

            </header>




            <div className="banner_shapes">
                <div className="container">
                    <span><img src="/assets/images/plus.svg" alt="image" /></span>
                    <span><img src="/assets/images/polygon.svg" alt="image" /></span>
                    <span><img src="/assets/images/round.svg" alt="image" /></span>
                </div>
            </div>


            <header className="fixed">

                <div className="container">


                </div>

            </header>

            <HomeTopBannerSection />

        </div>

    )


}

export default TopHomeWrapper