import React, { useEffect, useState, useRef, useCallback } from 'react'
import GoTop from "./GoTop";
import BottomFooter from './BottomFooter'
import FooterCol1 from "./FooterCol1";
import API from "../../../../modules/api";
import * as appConsts from '../../../../store/consts'
import { connect, useDispatch, useSelector } from 'react-redux'
import { addChangeView, addChangeLang, addChangeTranslateArr } from '../../../../store/pathActions'
import Util from "../../../../modules/utils";
import './Footer.css'
import RouteLink from '../../../Routing/RouteLink';
const Footer = () => {

    const utl = new Util();
    const dispatch = useDispatch()

    const lang = useSelector((state) => state.lang)
    const tranlates = useSelector((state) => state.tranlates)
    const locPath = useSelector((state) => state.path)
    const [netWorksState, setNetWorksState] = useState(
        [
            { name: "telegram", icon: "icofont-telegram", link: "/", isVisible: true, isEdit: false },
            { name: "whatsapp", icon: "icofont-whatsapp", link: "", isVisible: true, isEdit: false },
            { name: "twitter", icon: "icofont-twitter", link: "", isVisible: true, isEdit: false },
            { name: "facebook", icon: "icofont-facebook", link: "", isVisible: true, isEdit: false },
            { name: "youtube", icon: "icofont-youtube", link: "", isVisible: true, isEdit: false },
            { name: "instagram", icon: "icofont-instagram", link: "", isVisible: true, isEdit: false },
        ]
    );
    const [homeState, setHomeState] = useState(
        {
            logo_url: "/assets/images/Logo.png",

            icon1_url: "/assets/images/contact_01.png",
            icon2_url: "/assets/images/contact_02.png",
            icon3_url: "/assets/images/contact_03.png",


            contact_title: "Contact us",

            dsc: "Lorem Ipsum is simply dummy text of the printing and types etting industry lorem Ipsum has been the industrys standard dummy text ever since the when an unknown printer took a galley of type and.",

            link_title1: "Useful Links",
            link_name1: "footer1",
            link_items1: [],

            link_title2: "Reach us",
            link_name2: "footer2",
            link_items2: [],


            title1: "Reach us",
            dsc1: "599, Vilium Crossing, NJ",
            title2: "Call us",
            dsc2: "+1-900-123 4567",

            title3: "Email us",
            dsc3: "support@example.com",





        }
    );




    useEffect(() => {
        if (tranlates == undefined) {
            dispatch(addChangeTranslateArr(lang))
            // alert(lang);    
        }
        loadData();

//        //console.log("Lang", lang);

    }, [])


    useEffect(() => {

        if (tranlates == undefined) {
            dispatch(addChangeTranslateArr(lang))
            // alert(lang);    
        }
        loadData();



    }, [lang, locPath, tranlates])


    const loadMenus = (obj, result2) => {
        let arrNames = [obj.link_name1, obj.link_name2];
        (new API()).sendPostWithoutToken(`/api/links/names`, { names: arrNames }, (result) => {
            //console.log("MNU", result)
            // let obj = {};
            for (let index = 0; index < arrNames.length; index++) {
                const element = arrNames[index];
                if (result[arrNames[index]] != null) {


                    result[arrNames[index]].map((item) => {
                        item.title = utl.translateWord(result2, item.title);
                    });
                    obj[`link_items${index + 1}`] = result[arrNames[index]];
                } else {
                    obj[`link_items${index + 1}`] = [];
                }
            }

            //console.log("REWFDS", obj)
            setHomeState({ ...homeState, ...obj });
            //console.log("REWFDS", homeState)

        });
    }

    const loadData = () => {

        let arrNames = [];
        let arrImges = ["home-footer-logo-attach-id"];
        let arrTexts = [];
        let arrStatics = ['home-footer-dsc', 'home-footer-contact_title'];
        arrNames = [...arrNames, ...arrImges];
        arrNames = [...arrNames, ...arrStatics];
        for (let index = 1; index <= 3; index++) {
            arrNames = [...arrNames, `home-footer-icon${index}-attach-id`];
            arrImges = [...arrImges, `home-footer-icon${index}-attach-id`];
            const arr = ['title', 'dsc'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-footer-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-footer-${arr[index2]}${index}`]

            }



        }
        for (let index = 1; index <= 2; index++) {
            arrNames = [...arrNames, `home-footer-icon${index}-attach-id`];
            arrImges = [...arrImges, `home-footer-icon${index}-attach-id`];
            const arr = ['link_title', 'link_name'];
            for (let index2 = 0; index2 < arr.length; index2++) {
                arrNames = [...arrNames, `home-footer-${arr[index2]}${index}`];
                arrTexts = [...arrTexts, `home-footer-${arr[index2]}${index}`]

            }



        }


        for (let index = 0; index < netWorksState.length; index++) {
            const element = netWorksState[index];
            arrNames=[...arrNames,`home-footer-social-${element.name}_isVisible`];
            arrNames=[...arrNames,`home-footer-social-${element.name}_link`];
            
        }
        let obj = { ...homeState };
        try {

            tranlates.then(result2 => {
                (new API()).sendPostWithoutToken(`/api/settings/names`, { names: arrNames }, (result) => {
                    //console.log("TRE", result)

                    for (let index = 0; index < arrImges.length; index++) {
                        const element = arrImges[index];
                        const elementName = element.replace('home-footer-', '').replace('-attach-id');
                        if (result[element] != null)
                            obj[`image${index + 1}_url`] = `${appConsts.api_url}/api/attachments/file/${result[element]}`;

                    }
                    for (let index = 0; index < arrTexts.length; index++) {
                        const element = arrTexts[index];
                        const elementName = element.replace('home-footer-', '');
                        if (result[element] != null)
                            obj[elementName] = utl.translateWord(result2, result[element]);

                    }
                    for (let index = 0; index < arrStatics.length; index++) {

                        const element = arrStatics[index];

                        const elementName = element.replace('home-footer-', '');
                        if (result[element] != null)
                            obj[elementName] = utl.translateWord(result2, result[element]);

                    }
                    const nt=netWorksState.map((item)=>{
                        if (result[`home-footer-social-${item.name}_isVisible`] != null)
                            item.isVisible=(result[`home-footer-social-${item.name}_isVisible`]=="true")
                        if (result[`home-footer-social-${item.name}_link`] != null)
                            item.link=result[`home-footer-social-${item.name}_link`];
    
                        return item;
                    });
                   
                  // //console.log("NTB",nt);
                    setNetWorksState([...nt]);
               //     //console.log("WERTY", obj)
                    loadMenus(obj, result2);
                    //   setHomeState({ ...homeState, ...obj });
                });
            });

        } catch (error) {
            //console.log("ERRT", tranlates)
            //console.log("ERR", error)
        }



    }

const testClick=()=>{
    alert('');
}
    return (

        <footer>
            <div className="top_footer" id="contact">

                <div className="container">

                    <div className="row">
                        <FooterCol1 mySate={homeState} />

                        {
                            [...Array(2)].map((x, i) => {

                                return <div key={i} className="col-lg-2 col-md-6 col-12">
                                    <div className="links">
                                        <h3> {homeState[`link_title${i + 1}`]}</h3>
                                        <ul>
                                            {
                                                [...Array(homeState[`link_items${i + 1}`].length)].map((x2, i2) => {

//  
                                                   //return <li><a href={homeState[`link_items${i + 1}`][i2].href}>{homeState[`link_items${i + 1}`][i2].title}</a></li>
                                                    return <li key={i+i2}><RouteLink  href={homeState[`link_items${i + 1}`][i2].href}>{homeState[`link_items${i + 1}`][i2].title}</RouteLink></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>

                            })
                        }






                        <div className="col-lg-3 col-md-6 col-12">
                            <div className="try_out">
                                <h3>{homeState.contact_title}</h3>
                                <ul>

                                   


                                    <li>
                                        <span className="icon">
                                            <img src="/assets/images/contact_01.png" alt="image" />
                                        </span>
                                        <div className="text">
                                            <p>Reach us <br /> 599, Vilium Crossing, NJ</p>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="icon">
                                            <img src="/assets/images/contact_02.png" alt="image" />
                                        </span>
                                        <div className="text">
                                            <p>Call us <a href="tel:+1-900-1234567">+1-900-123 4567</a></p>
                                        </div>
                                    </li>
                                    <li>
                                        <span className="icon">
                                            <img src="/assets/images/contact_03.png" alt="image" />
                                        </span>
                                        <div className="text">
                                            <p>Email us <a href="mailto:support@example.com">support@example.com</a></p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </div>


            {<BottomFooter netWorksState={netWorksState}/>}

            <GoTop />
        </footer>
    )

}

export default Footer